import { PrintIframeAction } from "components/elements";
import { getSingleOrderPrintHTML } from "components/views/drawer/order-details/order-details-print-helper";
import { getSingleCardPrintHTML } from "components/views/drawer/order-details/trifold-print-helper";
import { getSingleInvoicePrintHTML } from "components/views/drawer/order-details/invoice-print-helper";
import { getSingleWorksheetPrintHTML } from "components/views/drawer/order-details/worksheet-print-helper";
import UserProfileStorage from "library/storage/userProfile";
import { Platform } from "react-native";
import isEmpty from "lodash/isEqual";
import { updateAutoPrintOrders } from "library/sagas/ongoing/current-orders/slice";
import { generateQRCode } from "library/sagas/ongoing/order-details/slice";
import { isMHQNonCoreMember } from "library/utils/entitlements";
import Environment from "library/utils/environment";

export const rejectReasons = [
  {
    label: "Order below minimum for SKU / product",
    value: "Below Min for SKU",
  },
  {
    label: "Order below minimum for distance",
    value: "Below Min for distance",
  },
  {
    label: "Customer canceled the order",
    value: "Customer Cancelled the Order",
  },
  {
    label: "Outside of delivery - Zip Code covered",
    value: "Outside of delivery - zip covered",
  },
  {
    label: "Outside of delivery - Zip Code not covered",
    value: "Outside of delivery - zip not covered",
  },
  {
    label: "Outside of delivery - remove Zip Code",
    value: "Outside of delivery - remove ZIP",
  },
  {
    label: "Stems Not Available",
    value: "Stems Not Available",
  },
  {
    label: "Container not available",
    value: "Containers Not Available",
  },
  {
    label: "Add-on not available",
    value: "Add on Not Available",
  },
  {
    label: "Recipient Not Available",
    value: "Recipient Not Available",
  },
  {
    label: "Patient discharged",
    value: "Patient discharged",
  },
  {
    label: "Too late for delivery - product",
    value: "Too late for delivery - product",
  },
  {
    label: "Too late for delivery - distance",
    value: "Too late for delivery - distance",
  },
  {
    label: "Too late for delivery - pool",
    value: "Too late for delivery - pool",
  },
  { label: "Other", value: "Other" },
];

export const cancelReasons = [
  {
    label: "Customer canceled the order",
    value: "Customer Cancelled the Order",
  },
  { label: "Other", value: "Other" },
];

export const cancelDenyReasons = [
  {
    label: "Already delivered",
    value: "Already Delivered",
  },
  { label: "Other", value: "Other" },
];

export const adjustmentReasons = [
  {
    label: "No order record found",
    value: "NO_RECORD",
  },
  { label: "Non-delivery", value: "NON_DELIVERY" },
  {
    label: "Overcharge",
    value: "OVERCHARGED",
  },
  {
    label: "Undercharge",
    value: "UNDERCHARGED",
  },
  {
    label: "Duplicate Charge",
    value: "DUPLICATE_CHARGE",
  },
  {
    label: "Paid Direct",
    value: "PAID_DIRECT",
  },
  {
    label: "Canceled",
    value: "ORDER_CANCELED",
  },
  {
    label: "Charged in Error",
    value: "CHARGED_IN_ERROR",
  },
  {
    label: "Incoming Order, not Outgoing",
    value: "INCOMING_NOT_OUTGOING",
  },
  {
    label: "Unsatisfactory",
    value: "UNSATISFACTORY",
  },
];

export const invoicePrintAction = [
  {
    key: "Invoice",
    label: "Invoice",
  },
];

export const printActions = [
  {
    key: "OrderDetail",
    label: "Order Detail",
  },
  {
    key: "TriFoldCard",
    label: "Tri-Fold Card",
  },
];

export const designerWorksheetAction = [
  { key: "DesignerWorksheet", label: "Worksheet" },
];

export const orderMessagesPrintAction = [
  { key: "OrderMessages", label: "Related Messages" },
];

export const printTrigger = ({
  selectedAction,
  orderDetails,
  memberCodes,
  operatorName: operator,
  userRole,
  messages,
  Localise,
  dispatch,
  selectedproducts,
  paymentDetails,
  cardInfo,
  imagesData,
  cardMessage,
  getZoneDetails,
  setSelectedAction,
  triggerAction,
  orderDetailResponse,
  ordersList = false,
  zoneDetailsInfo,
  deliveryInfo,
  recordId,
  isDSSubmitEligible,
  customerInfo,
}) => {
  const isElectronApp =
    Platform.OS === "web" &&
    document.getElementById("isElectronApp").value === "true";

  const macAddress =
    isElectronApp && document.getElementById("macAddress").value;

  const { memberCode: sendMemberCode } = orderDetails?.sendingMember;
  const { memberCode: recMemberCode } = orderDetails?.receivingMember;
  const isSenderOrder =
    orderDetails?.direction === "OUTBOUND" &&
    memberCodes.includes(sendMemberCode) &&
    sendMemberCode !== recMemberCode;
  const sourceMemberCode =
    orderDetails?.direction === "INBOUND"
      ? orderDetails?.receivingMember?.memberCode
      : orderDetails?.sendingMember?.memberCode;
  const { storePickupDateTime = "" } = orderDetails?.pickupInfo || {};
  const isStorePickupOrder = deliveryInfo?.deliveryMethod === "STORE_PICKUP";
  const isWalkInOrder = deliveryInfo?.deliveryMethod === "WALK_IN";
  const isPickUpOrder =
    ((orderDetails?.orderSource === "DoorDash" ||
      orderDetails?.orderSource === "UberEats") &&
      !isEmpty(storePickupDateTime)) ||
    isStorePickupOrder ||
    isWalkInOrder ||
    (orderDetails?.orderSource === "MOL" &&
      deliveryInfo?.deliveryMethod === "MOL_CUSTOMER_PICKUP");

  // QR Data Payload for generating the QR code
  const qrData = [
    {
      oid: orderDetails?.orderItemId || "", // orderItemId
      sm: sourceMemberCode || "", // sourceMemberCode based on orderDirection
      dm: deliveryInfo?.deliveryMethod || "", // order deliveryMethod
    },
  ];
  const {
    order_details_print_settings: printPreferencesArray = [],
    qr_code_global_enabled = "",
    qr_code_order_details_enabled = "",
    qr_code_trifold_enabled = "",
    qr_code_invoice_enabled = "",
    qr_code_worksheet_enabled = "",
  } = UserProfileStorage.getShopPreferences(sourceMemberCode);

  const showQrCode = qr_code_global_enabled === "true";

  const showOrderDetailsQrCode =
    showQrCode && qr_code_order_details_enabled === "true";
  const showTriFoldQrCode = showQrCode && qr_code_trifold_enabled === "true";
  const showInvoiceQrCode = showQrCode && qr_code_invoice_enabled === "true";
  const showWorksheetQrCode =
    showQrCode && qr_code_worksheet_enabled === "true";

  if (selectedAction === "accept-print" || selectedAction === "print") {
    let qrImageData = "";
    // order detail print trigger
    const orderDetailPrint = () => {
      const printPreferences =
        (isElectronApp &&
          printPreferencesArray.find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      PrintIframeAction(
        getSingleOrderPrintHTML({
          orderDetails,
          memberCodes,
          operatorName: operator,
          messages,
          Localise,
          selectedproducts,
          customerInfo,
          qrImageData,
        }),
        { printType: "orderDetails", preferences: printPreferences },
        () => {
          if (!orderDetails?.isPrinted) {
            if (!ordersList) {
              triggerAction({ action: selectedAction });
              setSelectedAction("");
            } else {
              triggerAction(
                selectedAction,
                deliveryInfo?.deliveryDate,
                recordId,
                deliveryInfo?.deliveryMethod,
                sourceMemberCode,
                isDSSubmitEligible
              );
            }
          }
        }
      );
    };

    showOrderDetailsQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              orderDetailPrint();
            },
            reject: () => {
              orderDetailPrint();
            },
          })
        )
      : orderDetailPrint();
  } else if (selectedAction === "triCardPrint") {
    let qrImageData = "";
    const triFoldPrintTrigger = async () => {
      const { cardSettings: cardSettingsText = "{}" } = cardInfo;
      const cardSettings = JSON.parse(cardSettingsText);
      const {
        isLogoOpted,
        isBannerOpted,
        logoImageURL,
        bannerImageURL,
        orderInfo = [],
        contactInfoBottomHalf = "Recipient Info",
      } = cardSettings;
      let imageUrl = "";

      const needDeliveryZone =
        contactInfoBottomHalf === "Delivery Info" &&
        orderInfo.includes("Delivery Zone");

      if (isLogoOpted) {
        imageUrl = logoImageURL;
      } else if (isBannerOpted) {
        imageUrl = bannerImageURL;
      }

      const imageData = imagesData[imageUrl];
      const fileInfo = {
        uri: imageData ? `data:image/png;base64,${imageData}` : "",
        name: imageUrl,
      };

      const zoneDetails =
        needDeliveryZone && !ordersList
          ? await getZoneDetails()
          : zoneDetailsInfo;

      const {
        trifold_card_print_settings: printPreferencesArray = [],
        additional_sympathy_card,
      } = UserProfileStorage.getShopPreferences(sourceMemberCode);

      const printPreferences =
        (isElectronApp &&
          printPreferencesArray?.find(
            (each) => each.macAddress === macAddress
          )) ||
        {};
      PrintIframeAction(
        getSingleCardPrintHTML({
          recipientInfo: orderDetails?.recipientInfo,
          receivingMember: orderDetails?.receivingMember,
          cardSettings,
          cardMessage,
          fileInfo,
          isPickUpOrder,
          orderSource: orderDetails?.orderSource,
          pickUpBy: deliveryInfo?.pickUpBy,
          numberOfPrints:
            additional_sympathy_card === "true" &&
            deliveryInfo?.occasion === "FUNERAL"
              ? 2
              : 1,
          qrImageData,
          zoneDetails,
          orderDetails,
        }),
        { printType: "trifold", preferences: printPreferences },
        () => {
          if (!orderDetails?.isTrifoldDetailsPrinted) {
            dispatch(
              updateAutoPrintOrders({
                payload: [
                  {
                    memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                    autoPrintOrders: [
                      {
                        orderId:
                          orderDetailResponse?.orderId ||
                          orderDetails?.orderItemId,
                        orderItemId: orderDetails?.orderItemId,
                        orderNumber: orderDetails?.erosOrderNumber,
                        isTrifoldDetailsPrinted: true,
                        deliveryMethod: deliveryInfo?.deliveryMethod,
                        sendingMemberCode: sendMemberCode,
                        fillingMemberCode: recMemberCode,
                        isAutoPrint: false,
                      },
                    ],
                  },
                ],
                resolve: () => {
                  if (!ordersList) {
                    triggerAction({ action: selectedAction });
                  }
                },
                reject: () => {
                  if (!ordersList) {
                    triggerAction({ action: selectedAction });
                  }
                },
              })
            );
          }
          if (!ordersList) setSelectedAction("");
        },
        "iframeId"
      );
    };

    showTriFoldQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              triFoldPrintTrigger();
            },
            reject: () => {
              triFoldPrintTrigger();
            },
          })
        )
      : triFoldPrintTrigger();
  } else if (selectedAction === "invoice") {
    let qrImageData = "";

    // invoice print trigger
    const invoicePrintTrigger = async () => {
      const zoneDetails = !ordersList
        ? await getZoneDetails()
        : zoneDetailsInfo;
      const {
        legal_size_invoice_print_settings: printPreferencesArray = [],
        additional_sympathy_invoice,
      } = UserProfileStorage.getShopPreferences(sourceMemberCode);

      const printPreferences =
        (isElectronApp &&
          printPreferencesArray.find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      PrintIframeAction(
        getSingleInvoicePrintHTML({
          orderDetails,
          memberCodes,
          messages,
          Localise,
          customerInfo,
          paymentDetails,
          userRole,
          operatorName: operator,
          zoneDetails,
          qrImageData,
          numberOfPrints:
            additional_sympathy_invoice === "true" &&
            deliveryInfo?.occasion === "FUNERAL"
              ? 2
              : 1,
        }),
        { printType: "invoice", preferences: printPreferences },
        () => {
          if (!orderDetails?.isInvoiceDetailsPrinted) {
            dispatch(
              updateAutoPrintOrders({
                payload: [
                  {
                    memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                    autoPrintOrders: [
                      {
                        orderId:
                          orderDetailResponse?.orderId ||
                          orderDetails?.orderItemId,
                        orderItemId: orderDetails?.orderItemId,
                        orderNumber: orderDetails?.erosOrderNumber,
                        isInvoiceDetailsPrinted: true,
                        deliveryMethod: deliveryInfo?.deliveryMethod,
                        sendingMemberCode: sendMemberCode,
                        fillingMemberCode: recMemberCode,
                        isAutoPrint: false,
                      },
                    ],
                  },
                ],
                resolve: () => {
                  if (!ordersList) {
                    triggerAction({ action: selectedAction });
                  }
                },
                reject: () => {
                  if (!ordersList) {
                    triggerAction({ action: selectedAction });
                  }
                },
              })
            );
          }
          if (!ordersList) setSelectedAction("");
        }
      );
    };

    showInvoiceQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              invoicePrintTrigger();
            },
            reject: () => {
              invoicePrintTrigger();
            },
          })
        )
      : invoicePrintTrigger();
  } else if (selectedAction === "designerWorksheetPrint") {
    let qrImageData = "";

    const designPrintTrigger = async () => {
      const zoneDetails = !ordersList
        ? await getZoneDetails()
        : zoneDetailsInfo;

      const { designer_worksheet_print_settings: printPreferencesArray = [] } =
        UserProfileStorage.getShopPreferences(sourceMemberCode);

      const printPreferences =
        (isElectronApp &&
          printPreferencesArray.find(
            (each) => each.macAddress === macAddress
          )) ||
        {};

      PrintIframeAction(
        getSingleWorksheetPrintHTML({
          orderDetails,
          operatorName: operator,
          messages,
          Localise,
          selectedproducts,
          customerInfo,
          paymentDetails,
          zoneDetails,
          qrImageData,
        }),
        {
          printType: "designerWorksheet",
          preferences: printPreferences,
        },
        () => {
          if (!orderDetails?.isDesignerWorkSheetPrinted) {
            dispatch(
              updateAutoPrintOrders({
                payload: [
                  {
                    memberCode: isSenderOrder ? sendMemberCode : recMemberCode,
                    autoPrintOrders: [
                      {
                        orderId:
                          orderDetailResponse?.orderId ||
                          orderDetails?.orderItemId,
                        orderItemId: orderDetails?.orderItemId,
                        orderNumber: orderDetails?.erosOrderNumber,
                        isDesignerWorkSheetPrinted: true,
                        deliveryMethod: deliveryInfo?.deliveryMethod,
                        sendingMemberCode: sendMemberCode,
                        fillingMemberCode: recMemberCode,
                        isAutoPrint: false,
                      },
                    ],
                  },
                ],
                resolve: () => {
                  if (!ordersList) {
                    triggerAction({ action: selectedAction });
                  }
                },
                reject: () => {
                  if (!ordersList) {
                    triggerAction({ action: selectedAction });
                  }
                },
              })
            );
          }
          if (!ordersList) setSelectedAction("");
        }
      );
    };

    showWorksheetQrCode
      ? dispatch(
          generateQRCode({
            params: { qrPayload: qrData },
            type: "SINGLE",
            resolve: (resp) => {
              qrImageData = resp[orderDetails?.orderItemId] || "";
              designPrintTrigger();
            },
            reject: () => {
              designPrintTrigger();
            },
          })
        )
      : designPrintTrigger();
  }
};

export const getIsPrintedOption = (
  isPrinted,
  isDesignerWorkSheetPrinted,
  isTrifoldDetailsPrinted,
  isInvoiceDetailsPrinted,
  isSideCarOpen,
  isWiredOrder,
  nonOLCMessages
) => {
  const printOptions = [
    { condition: isPrinted, value: "OrderDetail" },
    {
      condition: isDesignerWorkSheetPrinted,
      value: "DesignerWorksheet",
    },
    { condition: isTrifoldDetailsPrinted, value: "TriFoldCard" },
    { condition: isInvoiceDetailsPrinted, value: "Invoice" },
  ];
  if (!isSideCarOpen && isWiredOrder && nonOLCMessages.length) {
    printOptions.push({
      condition: orderMessagesPrintAction,
      value: "OrderMessages",
    });
  }

  return printOptions
    .filter((option) => option.condition)
    .map((option) => option.value);
};
export const getPrintActions = (
  sourceMemberCode,
  isSideCarOpen,
  isWiredOrder,
  nonOLCMessages
) => {
  const showOrderMessagePrintActions =
    !isSideCarOpen && isWiredOrder && nonOLCMessages.length;
  if (isMHQNonCoreMember(sourceMemberCode)) {
    const isDesignerWorksheetEnabled = Environment.get(
      "ENABLE_DESIGNER_WORKSHEET",
      true
    );

    return [
      ...printActions,
      ...invoicePrintAction,
      ...(isDesignerWorksheetEnabled ? designerWorksheetAction : []),
      ...(showOrderMessagePrintActions ? orderMessagesPrintAction : []),
    ];
  } else {
    return [
      ...printActions,
      ...(showOrderMessagePrintActions ? orderMessagesPrintAction : []),
    ];
  }
};
export const getExtendedMenuOptions = (
  isPrinted,
  isDesignerWorkSheetPrinted,
  isTrifoldDetailsPrinted,
  isInvoiceDetailsPrinted,
  isSideCarOpen,
  sourceMemberCode,
  isWiredOrder,
  nonOLCMessages
) => {
  const printedOptions = getIsPrintedOption(
    isPrinted,
    isDesignerWorkSheetPrinted,
    isTrifoldDetailsPrinted,
    isInvoiceDetailsPrinted,
    isSideCarOpen,
    isWiredOrder,
    nonOLCMessages
  );
  return getPrintActions(
    sourceMemberCode,
    isSideCarOpen,
    isWiredOrder,
    nonOLCMessages
  ).map((option) => ({
    ...option,
    selected: printedOptions.includes(option.key),
  }));
};
