import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { getPrice } from "library/utils/createOrder";
import { cardMessageFormatter } from "library/utils/formatter";

export const frequency = [
  {
    label: "1 week",
    value: "ONE_WEEK",
  },
  {
    label: "2 weeks",
    value: "TWO_WEEK",
  },
  {
    label: "3 weeks",
    value: "THREE_WEEK",
  },
  {
    label: "1 month",
    value: "ONE_MONTH",
  },
  {
    label: "1 year",
    value: "ONE_YEAR",
  },
];

export const subscriptionEnds = [
  {
    label: "3 months",
    value: "THREE_MONTH",
  },
  {
    label: "6 months",
    value: "SIX_MONTH",
  },
  {
    label: "1 year",
    value: "ONE_YEAR",
  },
  {
    label: "2 years",
    value: "TWO_YEAR",
  },
];

export const isModifyCheck = (
  values,
  subTotals,
  subscriptionDetails,
  setIsPaymentNeeded
) => {
  if (!subscriptionDetails?.isModify) return true;

  const { orderItems = [] } = values;

  const totalTax = orderItems[0]?.taxInfo?.taxAmount || 0;

  const orderTotal =
    totalTax +
    subTotals.reduce((a, b) => a + b) +
    parseFloat(getPrice(orderItems[0].deliveryFee)) +
    parseFloat(getPrice(orderItems[0].relayFee)) +
    parseFloat(getPrice(orderItems[0].retailDeliveryFee));

  const subscriptionOrderAmounts = get(
    subscriptionDetails,
    "orderTemplate.orderAmounts"
  );

  const amountChargedToCustomer =
    subscriptionOrderAmounts.find(
      (obj) => obj.name === "amountChargedToCustomer"
    )?.value || 0;

  if (
    parseFloat(orderTotal).toFixed(2) ===
    parseFloat(amountChargedToCustomer).toFixed(2)
  ) {
    setIsPaymentNeeded(false);
    return false;
  } else {
    setIsPaymentNeeded(true);
    return true;
  }
};

export const getCreateSubscriptionReqObj = (
  createOrderReqObj,
  creditCardId,
  subscriptionDetails
) => {
  const {
    sendingMember,
    customerInfo,
    subscriptionInfo,
    orderItems,
    paymentDetails,
    orderAmounts,
    enablePayment,
    isModify,
  } = createOrderReqObj;

  const { id: subscriptionId = "" } = subscriptionDetails;

  const newPaymentInfo = cloneDeep(paymentDetails);
  const paymentMethodType = get(
    newPaymentInfo,
    "paymentMethod.0.paymentMethodType",
    ""
  );

  const paymentInfoWithCCid = set(
    newPaymentInfo,
    "paymentMethod.0.paymentMethodDetails",
    {
      creditCardId,
    }
  );
  const orderItemsCopy = cloneDeep(orderItems);
  const cardMessage = orderItems[0]?.deliveryInfo?.cardMessage
    ? cardMessageFormatter(orderItems[0]?.deliveryInfo?.cardMessage)
    : "N/A";

  if (cardMessage === "N/A") {
    set(orderItemsCopy, "0.deliveryInfo.cardMessage", cardMessage);
  }

  set(orderItemsCopy, "0.deliveryInfo.deliveryMethod", "FLORIST_DELIVERED"); //Subscription orders are always florist delivered.

  const reqObj = {
    shopCode: sendingMember,
    customerId: customerInfo.customerId,
    subscriptionId,
    active: true,
    contactId: customerInfo?.contactId || "",
    orderTemplate: {
      orderItems: orderItemsCopy,
      deliveryMethod: "FLORIST_DELIVERED",
      ...(!isModify
        ? {
            paymentDetails:
              paymentMethodType !== "INVOICE"
                ? paymentInfoWithCCid
                : paymentDetails,
            orderAmounts,
          }
        : {
            paymentDetails: enablePayment
              ? paymentMethodType !== "INVOICE"
                ? paymentInfoWithCCid
                : paymentDetails
              : subscriptionDetails.orderTemplate.paymentDetails,
            orderAmounts: enablePayment
              ? orderAmounts
              : subscriptionDetails.orderTemplate.orderAmounts,
          }),
    },
    schedule: {
      repeat: [
        {
          firstDeliveryDate: moment(subscriptionInfo?.firstDeliveryDate).format(
            "YYYY-MM-DD"
          ),
          frequency: subscriptionInfo?.frequency || "",
          ends: subscriptionInfo?.ends || "NEVER",
        },
      ],
      skipDate: [],
    },
  };

  return reqObj;
};
